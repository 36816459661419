import React from 'react'

import Cart from '~/components/Cart'
import Layout from '~/layouts/layout'

const CartPage = () => (
  <Layout language="nl" en="cart" nl="winkelwagen">
    <div className="articleHero gridFullWidth">
      <h1>Mijn winkelwagen</h1>
    </div>

    <Cart language="nl" />
  </Layout>
)

export default CartPage
